import { PagedRequest } from "@domain/services/paged-request";
import { Observable } from "rxjs";
import { ListCustomerQuery, ListCustomerResponse } from "../dto/list-customer.dto";
import { ShowCustomerResponse } from "../dto/read-customer.dto";
import { ChangeStatusPayload, WriteCustomerPayload, WriteCustomerResponse } from "../dto/write-customer.dto";

export abstract class CustomerRepository implements PagedRequest {
    abstract show(id: number): Observable<ShowCustomerResponse>;

    abstract list(query?: ListCustomerQuery): Observable<ListCustomerResponse>;

    abstract create(payload:WriteCustomerPayload): Observable<WriteCustomerResponse>;

    abstract update(payload:WriteCustomerPayload): Observable<WriteCustomerResponse>;

    abstract changeStatus(id: ChangeStatusPayload): Observable<WriteCustomerResponse>;
}
